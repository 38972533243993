import React from "react";
import styled, { css } from "styled-components";
import device from "../common/breakpoints";

import { useNavContext } from "../context/navigation-context";

const Styled = styled.div`
  background-color: white;
  transition: transform 0.4s, opacity 0.4s;

  ${(props) =>
    props.isNavOpen
      ? css`
          opacity: 0.8;
          transform: perspective(200px) translate3d(0px, 32%, -6vmax)
            rotateX(-10deg);
        `
      : css`
          opacity: 1;
          transform: perspective(200px) translate3d(0px, 0px, 0px) rotateX(0deg);
        `};

  @media ${device.tablet} {
    ${(props) =>
      props.isNavOpen &&
      css`
        opacity: 0.8;
        transform: perspective(300px) translate3d(0px, 16%, -6vmax)
          rotateX(-2deg);
      `};
  }

  @media ${device.tabletL} {
    ${(props) =>
      props.isNavOpen &&
      css`
        opacity: 0.8;
        transform: perspective(300px) translate3d(0px, 14vmax, -6vmax)
          rotateX(-2deg);
      `};
  }

  @media ${device.laptopL} {
    ${(props) =>
      props.isNavOpen &&
      css`
        opacity: 0.8;
        transform: perspective(300px) translate3d(0px, 16%, -6vmax)
          rotateX(-2deg);
      `};
  }
`;

function AnimatedPageContainer({ children }) {
  const { isNavOpen } = useNavContext();
  return <Styled isNavOpen={isNavOpen}>{children}</Styled>;
}

export default AnimatedPageContainer;
