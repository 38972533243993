import styled, { css } from "styled-components";

import device, { size } from "../../common/breakpoints";
import BackgroundImageBig from "../../assets/images/background-big.jpg";
import BackgroundImageSmall from "../../assets/images/background-small.jpg";

const PageContainer = styled.div`
  background-image: url(${BackgroundImageSmall}), url(${BackgroundImageSmall});
  background-position: left -24vh, left 78vh;
  background-size: contain, contain;
  background-repeat: no-repeat;
  background-color: white;
  width: 100vw;
  max-width: 1920px;
  padding-left: 4vw;
  padding-right: 4vw;
  height: 100vh;
  -webkit-overflow-scrolling: touch;

  ${(props) =>
    props.experiencePage &&
    css`
      background-size: cover, contain;
      background-position: left 0, left 78vh;
      overflow-x: hidden;
    `}

  ${(props) =>
    props.characterPage &&
    css`
      background-size: cover, contain;
      background-position: center 0, left 78vh;
      overflow-x: hidden;
    `}
  
  ${(props) =>
    props.demosPage &&
    css`
      background-size: cover, contain;
      background-position: right 0, left 78vh;
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;

      @media ${device.laptopS} {
        overflow: auto;
        padding-left: 4vw; /* restore to default per other sections */
        padding-right: 4vw; /* restore to default per other sections */
      }
    `}

  @media (max-width: ${size.tablet}px) and (orientation: landscape) {
    background-image: none;
  }

  @media ${device.tablet} {
    background-image: url(${BackgroundImageBig}), url(${BackgroundImageBig});
    background-position: left -62vh, center 62vh;
    background-size: cover, cover;
  }

  @media ${device.tabletL} {
    background-position: left -48vmax, right 40vmax;
  }
`;

export default PageContainer;
