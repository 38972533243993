import React, { useState } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import device from "../common/breakpoints";

const StyledButton = styled.button`
  &.btn {
    width: 100%;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    text-align: left;
    color: white;
    background-color: var(--color_link);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color_link);
    border-radius: 6px;
    opacity: 0.9;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media ${device.tablet} {
      padding: 0.8rem 1rem;
    }

    &:focus {
      outline: 0 none;
    }

    &.clicked {
      color: #dfdfdf;
      .icon {
        display: none;
      }
    }

    &.active {
      background-color: var(--color_link_selected);
      border-color: var(--color_link_selected);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .icon {
      margin-left: 0.6rem;
      vertical-align: text-bottom;
    }
  }
`;

const ButtonViewMore = ({ children, onClick, isActive }) => {
  const [clicked, setClicked] = useState(isActive);
  return (
    <StyledButton
      className={clsx("btn", { clicked, active: isActive })}
      onClick={() => {
        setClicked(true);
        onClick();
      }}
    >
      <span>{children}</span>
      <FontAwesomeIcon className="icon" icon={faAngleRight} />
    </StyledButton>
  );
};

export default ButtonViewMore;
