import React, { useState } from "react";
import clsx from "clsx";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import device from "../common/breakpoints";
import useMedia from "../hooks/useMedia";
import ButtonViewMore from "../components/ButtonViewMore";
import ContentContainer from "../components/styled/ContentContainer";
import ContentSection from "../components/styled/ContentSection";
import PageContainer from "../components/styled/PageContainer";
import Technologies from "../components/Technologies";
import Testimonial from "../components/Testimonial";
import Testimonials from "../components/Testimonials";
import SwipeHandler from "../components/SwipeHandler";

function Experience() {
  const isTablet = useMedia([device.tablet], [true], false);
  const [index, setIndex] = useState(isTablet ? 6 : null);

  const onSelect = (index) =>
    setIndex((currentIndex) =>
      isTablet ? index : currentIndex === index ? null : index
    );

  return (
    <SwipeHandler>
      <PageContainer experiencePage>
        <ContentContainer>
          <ContentSection>
            <h1>Experience</h1>
            <div className={clsx("container", { "view-all": index === null })}>
              <ul>
                <li className={clsx("item", { active: index === 6 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(6)}
                    isActive={index === 6}
                  >
                    Within the past year ...
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Technologies applied within the past year</h3>
                    <p>
                      I'm currently working or have enjoyed working with the
                      following technologies over the past 12 months:
                    </p>
                    <Technologies />
                    <button onClick={() => onSelect(6)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 1 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(1)}
                    isActive={index === 1}
                  >
                    Transforms requirements into features
                  </ButtonViewMore>
                  <div className="content">
                    <h3>
                      Understands and transforms requirements into features
                    </h3>
                    <p>
                      Since launching my career, I've had the opportunity to
                      develop and contribute to a variety of projects and
                      industries, including advertising, govtech, tech
                      marketing, and e-learning. I have been responsible for
                      assessing the technical viability of designs before
                      proceeding to their implementation. I have also had the
                      experience to work closely with a product team and/or
                      design leads to elevate functionality or look for
                      opportunities to suggest improvements to the user
                      experience in response to a series of requirements.
                    </p>
                    <button onClick={() => onSelect(1)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 2 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(2)}
                    isActive={index === 2}
                  >
                    Writes modular and maintainable code
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Writes modular and maintainable code</h3>
                    <p>
                      Over the years, I have strived to develop high-quality,
                      maintainable solutions while adopting new technologies and
                      keeping up with best practices. After some initial
                      experience writing plugins in jQuery, I started working
                      with AngularJS in 2011. That's when I was introduced to
                      the idea of building web applications using components.
                      Later on, I adopted this approach successfully with Ember,
                      and it helped me transition to React. A few years back, I
                      also wrote a small library, a Javascript API to use on top
                      of a REST API for an open source project.
                    </p>
                    <button onClick={() => onSelect(2)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 4 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(4)}
                    isActive={index === 4}
                  >
                    Quality and best practices
                  </ButtonViewMore>
                  <div className="content">
                    <h3>
                      Develops features with quality and best practices in mind
                    </h3>
                    <p>
                      Ever since my first job working for a digital ad agency
                      &mdash;where some of the clients were Fortune 500
                      companies&mdash;, work was held to high-standards both in
                      terms of aesthetics and functionality. This first work
                      experience as a web developer helped me grow a keen eye
                      for detail and learn to debug, fix or work around browser
                      incompatibilities. It also brought{" "}
                      <a
                        rel="external nofollow noopener"
                        target="blank"
                        href="https://www.w3.org/WAI/fundamentals/accessibility-principles/"
                      >
                        accessibility principles
                      </a>
                      , WCAG and WAI to my attention.
                    </p>
                    <button onClick={() => onSelect(4)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 3 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(3)}
                    isActive={index === 3}
                  >
                    Respects deadlines
                  </ButtonViewMore>
                  <div className="content">
                    <h3>
                      Produces estimates and delivers work on expected deadlines
                    </h3>
                    <p>
                      As a front-end lead developer and as an independent
                      contractor, I have been frequently asked to provide
                      estimates on projects or new features and manage their
                      progress through timesheets. I'm also used to meeting hard
                      deadlines and re-prioritizing work and deliverables based
                      on business needs.
                    </p>
                    <button onClick={() => onSelect(3)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 5 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(5)}
                    isActive={index === 5}
                  >
                    Works well within a small team
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Works well within a small team</h3>
                    <p>
                      My first job as a web developer was for a digital
                      advertising agency where I was one of the first four
                      hires. All other companies that I have worked for have had
                      less than 30 people when I joined, each one implementing
                      their own version of scrum or kanban usually broken up
                      into small teams of 4-6 people.
                    </p>
                    <button onClick={() => onSelect(5)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
              </ul>
              <Testimonials>
                <Testimonial
                  author="Erez Druk"
                  title="Co-Founder and CTO at UrbanLeap"
                >
                  Aside from a deep technical background and strong web
                  development experience, David also brought to the table good
                  product and design sense which was a big advantage for our
                  small team.
                </Testimonial>
                <Testimonial author="Sebastien Mirolo" title="CEO at DjaoDjin">
                  I really appreciate his pro-active approach to bring issues
                  and questions forward.
                </Testimonial>
                <Testimonial
                  author="Mary P. Fox"
                  title="Founder of retail tech startup"
                >
                  He has an amazing eye for detail, takes pride in the quality
                  of his craft and balances innovative thoughts and ideas with
                  industry best practices, always keeping the customer
                  experience in mind.
                </Testimonial>
              </Testimonials>
            </div>
          </ContentSection>
        </ContentContainer>
      </PageContainer>
    </SwipeHandler>
  );
}

export default Experience;
