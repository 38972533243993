import { useEffect, useRef } from "react";

export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before useEffect is run)
  return ref.current;
}
