import React, { useState, useLayoutEffect } from "react";

import useWindowSize from "../hooks/useWindowSize";

const AnimationsContext = React.createContext();

function AnimationsContextProvider(props) {
  const [width, height] = useWindowSize();
  const [isAnimationsEnabled, setIsAnimationsEnabled] = useState(true);

  useLayoutEffect(() => {
    // Whenever the window is resized, re-enable animations
    setIsAnimationsEnabled(true);
  }, [width, height]);

  return (
    <AnimationsContext.Provider
      value={{ isAnimationsEnabled, setIsAnimationsEnabled }}
      {...props}
    />
  );
}

function useAnimationsContext() {
  const context = React.useContext(AnimationsContext);
  if (context === undefined) {
    throw new Error(
      `useAnimationsContext must be used within an AnimationsContextProvider`
    );
  }
  return context;
}

export { AnimationsContextProvider, useAnimationsContext };
