import React from "react";
import styled from "styled-components";
import device from "../common/breakpoints";
import Greeting from "./Greeting";

const Container = styled.div`
  /* Apply font anti-alias effects:
    https://stackoverflow.com/questions/17864742/how-to-apply-font-anti-alias-effects-in-css
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding-top: 6vh;

  @media ${device.laptopS} {
    padding-top: 8vh;
  }

  @media ${device.laptopL} {
    padding-top: 12vmin;
  }

  h1,
  h2 {
    font-weight: normal;
  }

  > div > h1 {
    display: inline-block;
    color: #000;
    font-size: 7.6vmin;
    margin-left: 4vw;

    @media ${device.tablet} {
      font-size: 6vmin;
      margin-left: 3vw;
    }

    @media ${device.laptopL} {
      margin-left: 2vw;
    }
  }

  > p {
    width: 80vw;
    margin-top: 2vmax;
    font-size: 5.2vmin;
    line-height: 1.2;

    @media ${device.tablet} {
      margin-top: 3vmax;
      font-size: 4vmin;
    }

    @media ${device.tabletL} {
      margin-top: 2vmax;
      font-size: 3.6vmin;
      width: 42vw;
    }

    @media ${device.laptopL} {
      margin-top: 4vh;
      width: 50vw;
    }
  }

  > h2 {
    width: 80vw;
    margin-top: 2vmax;
    font-size: 4.2vmin;
    line-height: 1.4;

    @media ${device.tablet} {
      width: 46vw;
      font-size: 3vmin;
    }

    @media ${device.laptopS} {
      width: 52vw;
      margin-top: 2vmin;
    }
  }
`;

function HeroText() {
  return (
    <Container>
      <div>
        <Greeting>Hello</Greeting>
        <h1>I'm David Quirós.</h1>
      </div>
      <p>I build tools on the internet for people &mdash;like you and me.</p>
      <h2>
        I'm a <b>Front-End Engineer</b> working as an{" "}
        <b>independent contractor</b> developing web applications in{" "}
        <b>JavaScript</b> and <b>React</b>.
      </h2>
    </Container>
  );
}

export default HeroText;
