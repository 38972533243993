import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Color,
  Geometry,
  OrthographicCamera,
  Points,
  PointsMaterial,
  Scene,
  Vector3,
  WebGLRenderer
} from "three";

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: hidden;
`;

const centerVector = new Vector3(0, 0, 0);

function ParticleImage(props) {
  /*
   * Image element
   */
  const [imageEl, setImageEl] = useState();
  // To run some code when React attaches or detaches a ref to a DOM node,
  // you may want to use a callback ref instead:
  // https://reactjs.org/docs/hooks-reference.html#useref
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const updateImageRef = useCallback(node => {
    if (node !== null) {
      setImageEl(node);
    }
  }, []);

  /*
   * Image data
   */
  const [imageData, setImageData] = useState();
  const getImageData = () => {
    const canvas = document.createElement("canvas");
    canvas.width = imageEl.width;
    canvas.height = imageEl.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageEl,
      0,
      0,
      imageEl.naturalWidth,
      imageEl.naturalHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );
    setImageData(ctx.getImageData(0, 0, imageEl.width, imageEl.height));
  };

  /*
   * Image animation
   */
  const [renderer, setRenderer] = useState();
  const [camera, setCamera] = useState();
  const [scene, setScene] = useState();

  const updateAnimateCanvasRef = useCallback(
    node => {
      if (node !== null && imageData) {
        // Setup scene
        const ww = imageData.width;
        const wh = imageData.height;
        const renderer = new WebGLRenderer({
          canvas: node,
          antialias: true,
          alpha: true
        });
        const scene = new Scene();
        const camera = new OrthographicCamera(
          ww / -2,
          ww / 2,
          wh / 2,
          wh / -2,
          1,
          1000
        );

        renderer.setSize(ww, wh);
        camera.position.set(0, -20, 4);
        camera.lookAt(centerVector);
        scene.add(camera);
        camera.zoom = 1;
        camera.updateProjectionMatrix();

        // Save scene variables
        setRenderer(renderer);
        setCamera(camera);
        setScene(scene);
      }
    },
    [imageData]
  );

  const particles = useMemo(() => {
    if (!imageData) return null;

    const geometry = new Geometry();
    const material = new PointsMaterial();
    const speed = 10;

    function getPixel(imgData, x, y) {
      const position = (x + imgData.width * y) * 4;
      const { data } = imgData;
      return {
        r: data[position],
        g: data[position + 1],
        b: data[position + 2],
        a: data[position + 3]
      };
    }

    material.vertexColors = true;
    material.transparent = true;

    for (let y = 0, y2 = imageData.height; y < y2; y += 1) {
      for (let x = 0, x2 = imageData.width; x < x2; x += 1) {
        var vertex = new Vector3();
        vertex.x = x - imageData.width / 2;
        // why this is 1.3 is not clear but it depends on --image_height
        vertex.y = -y + imageData.height / 2;
        vertex.z = -Math.random() * 500;

        vertex.speed = Math.random() / speed + 0.015;

        const { r, g, b } = getPixel(imageData, x, y);
        const color = `rgb(${r}, ${g}, ${b})`;
        geometry.colors.push(new Color(color));
        geometry.vertices.push(vertex);
      }
    }
    return new Points(geometry, material);
  }, [imageData]);

  const renderImageAnimation = useCallback(() => {
    if (particles) {
      const cameraYPos = camera.position.y;
      if (cameraYPos < -0.00001) {
        // Keep animation cycle until camera position stabilizes
        requestAnimationFrame(renderImageAnimation);
        particles.geometry.verticesNeedUpdate = true;
        camera.position.y += (0 - camera.position.y) * 0.06;
        camera.lookAt(centerVector);
        renderer.render(scene, camera);
      }
    }
  }, [camera, particles, renderer, scene]);

  useEffect(() => {
    if (scene && particles) {
      scene.add(particles);
      requestAnimationFrame(renderImageAnimation);
    }
  }, [particles, renderImageAnimation, scene]);

  return (
    <>
      <Image
        ref={updateImageRef}
        src={props.src}
        alt={props.alt}
        onLoad={getImageData}
      />
      <canvas ref={updateAnimateCanvasRef} />
    </>
  );
}

export default ParticleImage;
