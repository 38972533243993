import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { faHome, faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { useAnimationsContext } from "../context/animations-context";
import { useNavContext } from "../context/navigation-context";
import usePrevious from "../hooks/usePrevious";
import device from "../common/breakpoints";
import HamburgerIcon, { StyledHamburger } from "./icons/Hamburger";
import SocialMediaIcons, {
  SocialMediaIconsContainer,
} from "./SocialMediaIcons";

const NAV_ANIMATION_TIME = 400;

const StyledAppNavigation = styled.div`
  position: relative;

  ${StyledHamburger} {
    top: 4vmin;
    left: 5vmin;

    @media ${device.tablet} {
      top: 3.6vmax;
      left: 3vmax;
    }
    @media (min-width: 1750px) {
      left: 4.6vw;
    }
  }

  ${SocialMediaIconsContainer} {
    opacity: 0.6;

    button,
    a {
      font-size: 1.4rem;

      @media ${device.laptopS} {
        font-size: 1.6rem;
      }
    }
  }

  & .animated-nav-enter {
    opacity: 0;
  }
  & .animated-nav-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in 300ms;
  }
  & .animated-nav-exit {
    opacity: 1;
  }
  & .animated-nav-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  & > nav {
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > ul {
      margin-top: 3vh;

      /* Test support for retina displays: https://wiki.mozilla.org/DevTools/CSSTips#HDPI_support */
      @media ${device.tabletL} and (min-resolution: 1.1dppx) {
        margin-top: 3vh;
      }

      & > li {
        display: inline-block;

        & button,
        & a {
          position: relative;
          transition: all 0.3s ease;
          color: var(--color_text_inverse);
          text-decoration: none;
          font-size: 0.9rem;
          font-weight: bold;
          text-transform: uppercase;
          margin-left: 1rem;
          margin-right: 1rem;
          display: inline-block;

          @media ${device.tablet} {
            letter-spacing: 1px;
            margin-left: 2rem;
            margin-right: 2rem;
          }

          @media ${device.laptopL} {
            font-size: 1.1rem;
          }

          &:hover {
            color: var(--color_link);
          }
        }
      }

      &.links {
        & button::before {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: 30px;
          height: 2px;
          margin: 5px 0 0 -15px;
          background: var(--color_link);
          transition: transform 0.3s;
          transform: scale3d(0, 1, 1);
        }
        & button:hover {
          &:before {
            transform: scale3d(1, 1, 1);
          }
        }
      }

      &.icons,
      ${SocialMediaIconsContainer} {
        button,
        a {
          font-size: 1.8rem;
          transition: all 0.3s;

          @media ${device.tablet} {
            font-size: 1.6rem;
          }

          @media ${device.laptopL} {
            font-size: 1.8rem;
          }

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .copyright {
      margin-top: 0.5rem;
      color: #8a8a8a;

      small {
        @media ${device.tabletL} {
          font-size: 0.7rem;
        }
        @media ${device.tabletL} and (min-resolution: 1.1dppx) {
          font-size: 0.9rem;
        }
      }
    }
  }
`;

function NavButton({ children, to, currentPath }) {
  const { setIsNavOpen } = useNavContext();
  return (
    <button
      type="button"
      onClick={() => {
        setIsNavOpen(false);
        // Wait before updating location
        setTimeout(function () {
          if (to !== currentPath && `/${to}` !== currentPath) {
            navigate(to);
          }
        }, NAV_ANIMATION_TIME);
      }}
    >
      {children}
    </button>
  );
}

function AppNavigation({ location }) {
  const prevLocation = usePrevious(location.pathname);
  const { setIsAnimationsEnabled } = useAnimationsContext();
  const { isNavOpen } = useNavContext();
  const isHome = location.pathname === "/";
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (prevLocation && prevLocation !== location.pathname) {
      setIsAnimationsEnabled(false);
    }
  }, [location.pathname, prevLocation, setIsAnimationsEnabled]);

  return (
    <StyledAppNavigation>
      {!isHome && <HamburgerIcon className="toggle" />}
      <CSSTransition
        in={isNavOpen}
        timeout={NAV_ANIMATION_TIME}
        classNames="animated-nav"
      >
        <nav>
          <ul className="icons">
            <li>
              <NavButton to="/" currentPath={location.pathname}>
                <FontAwesomeIcon icon={faHome} />
              </NavButton>
            </li>
            <li>
              <a href="mailto:david@quiros.online">
                <FontAwesomeIcon icon={faComments} />
              </a>
            </li>
          </ul>
          <ul className="links">
            <li>
              <NavButton to="character" currentPath={location.pathname}>
                Character
              </NavButton>
            </li>
            <li>
              <NavButton to="experience" currentPath={location.pathname}>
                Experience
              </NavButton>
            </li>
            <li>
              <NavButton to="demos" currentPath={location.pathname}>
                Demos
              </NavButton>
            </li>
          </ul>
          <SocialMediaIcons className="social-media" />
          <p className="copyright">
            <small>
              &copy; {currentYear} David Quirós &mdash;quiros.online
            </small>
          </p>
        </nav>
      </CSSTransition>
    </StyledAppNavigation>
  );
}

export default AppNavigation;
