import React from "react";
import styled, { keyframes } from "styled-components";

import device from "../common/breakpoints";

const bounceEffect = keyframes`
  0%, 100% { 
    transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
  }
`;

const Styled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  .spinner {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10vmax;
    height: 10vmax;

    @media ${device.tablet} {
      width: 8vmax;
      height: 8vmax;
    }

    @media ${device.tabletL} {
      width: 6vmax;
      height: 6vmax;
    }
  }

  .bounce1,
  .bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: ${bounceEffect} 2s infinite ease-in-out;
  }
  .bounce1 {
    background-color: #f7bbde;
  }

  .bounce2 {
    background-color: #feede7;
    animation-delay: -1s;
  }
`;

const Loading = props => {
  if (true) {
    return (
      <Styled>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
        </div>
      </Styled>
    );
  }
  return null;
};

export default Loading;
