import React, { useEffect } from "react";
import { Router, Location } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { AnimationsContextProvider } from "./context/animations-context";
import { NavContextProvider } from "./context/navigation-context";
import AnimatedPageContainer from "./components/AnimatedPageContainer";
import AppNavigation from "./components/AppNavigation";
import Character from "./pages/Character";
import ContentContainer from "./components/styled/ContentContainer";
import device from "./common/breakpoints";
import Demos from "./pages/Demos";
import Experience from "./pages/Experience";
import Home from "./pages/Home";
import PageContainer from "./components/styled/PageContainer";
import useMedia from "./hooks/useMedia";
import "./App.css";

const StyledApp = styled.div`
  .fade-enter {
    ${PageContainer} {
      opacity: 0;
      z-index: 1;

      ${ContentContainer} {
        opacity: 0;
      }
    }
  }

  .fade-enter.fade-enter-active {
    ${PageContainer} {
      opacity: 1;
      transition: opacity 400ms ease-in;

      ${ContentContainer} {
        opacity: 1;
        transition: opacity 500ms ease-in 500ms;
      }
    }
  }
`;

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={1100}>
          <Router location={location} className="router">
            {props.children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

function App() {
  const isTablet = useMedia([device.tablet], [true], false);

  useEffect(() => {
    if (
      "orientation" in window.screen &&
      window.screen.orientation.lock &&
      !isTablet
    ) {
      // Keep mobile devices in portrait mode
      window.screen.orientation.lock("portrait");
    }
  }, [isTablet]);

  return (
    <StyledApp>
      <AnimationsContextProvider>
        <NavContextProvider>
          <Router primary={false}>
            <AppNavigation path="*" />
          </Router>

          <AnimatedPageContainer>
            <FadeTransitionRouter>
              <Demos path="demos" />
              <Character path="character" />
              <Experience path="experience" />
              <Home path="/" />
            </FadeTransitionRouter>
          </AnimatedPageContainer>
        </NavContextProvider>
      </AnimationsContextProvider>
    </StyledApp>
  );
}

export default App;
