import React from "react";
import styled, { keyframes } from "styled-components";

import { useAnimationsContext } from "../context/animations-context";
import device from "../common/breakpoints";

const textColor = "#3c3c3c";
const inverseTextColor = "white";
const animationCurveFn = "cubic-bezier(0.77, 0, 0.175, 1)";

const inverseColorEffect = keyframes`
  0% {color: ${textColor}; }
  100% {color: ${inverseTextColor}; }
`;

const slideInEffect = keyframes`
  0% {width: 0;}
  100% {width: 100%;}
`;

const StaticContainer = styled.div`
  display: inline-block;
  position: relative;
  padding: 0 4vw;
  padding-right: 2vw;
  color: ${inverseTextColor};
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  user-select: none;
  background-color: ${textColor};

  @media ${device.tablet} {
    padding: 1.2rem 3.6rem;
    padding-right: 1.2rem;
  }

  @media ${device.tabletL} {
    padding: 1.2rem 4rem;
    padding-right: 2rem;
  }

  > em {
    position: relative;
    font-style: normal;
    font-size: 6vmin;
    line-height: 2.6;
    z-index: 2;

    @media ${device.tablet} {
      font-size: 5vmin;
      line-height: 1.3;
    }
  }
`;

const AnimatedContainer = styled(StaticContainer)`
  color: ${textColor};
  background-color: ${inverseTextColor};
  animation: ${inverseColorEffect} 1000ms ${animationCurveFn} 2s;
  animation-fill-mode: forwards;

  ::before,
  ::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
  }
  ::before {
    right: 0;
    border: 1px solid ${textColor};
    border-left: 0;
    border-right: 0;
    animation: ${slideInEffect} 1000ms ${animationCurveFn} 1.4s;
    animation-fill-mode: forwards;
  }
  ::after {
    left: 0;
    background-color: ${textColor};
    animation: ${slideInEffect} 1000ms ${animationCurveFn} 1.8s;
    animation-fill-mode: forwards;
  }
`;

function Greeting(props) {
  const { isAnimationsEnabled } = useAnimationsContext();

  return isAnimationsEnabled ? (
    <AnimatedContainer>
      <em>{props.children}</em>
    </AnimatedContainer>
  ) : (
    <StaticContainer>
      <em>{props.children}</em>
    </StaticContainer>
  );
}

export default Greeting;
