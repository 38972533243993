import React from "react";
import styled from "styled-components";

import device from "../common/breakpoints";

const StyledQuote = styled.blockquote`
  position: relative;
  padding: 0.8rem 0.8rem 0.8rem 2rem;
  margin: 1vmax 0 0;
  background-color: white;
  opacity: 0.8;
  border-radius: 5px;

  @media ${device.tablet} {
    margin: 0;
    padding: 1rem 1rem 1rem 2.4rem;
    max-width: 620px;
    font-size: 1.1rem;
  }

  @media ${device.tabletL} {
    font-size: 1rem;
    margin-right: 3vw;
    max-width: 780px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  @media ${device.laptopS} {
    padding: 1.5rem 1rem 1.5rem 2.4rem;
    font-size: 1.1rem;
    margin-right: 4vw;
    box-shadow: none;
  }

  &::before {
    content: "“";
    position: absolute;
    top: -32px;
    left: -12px;
    font-size: 7rem;

    @media ${device.tablet} {
      top: -22px;
      left: -12px;
    }

    @media ${device.laptopS} {
      top: -10px;
      left: -12px;
    }
  }

  p {
    line-height: 1.2;
    padding-bottom: 0.2rem;

    @media ${device.tablet} {
      line-height: 1.4;
      padding-bottom: 0.3rem;
    }

    &::after {
      content: "”";
    }
  }

  small {
    @media ${device.tablet} {
      font-size: 0.9rem;
      line-height: 1.4;
    }
    @media ${device.laptopS} {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
`;

const Testimonial = ({ children, author, title, location }) => {
  return (
    <StyledQuote>
      <p>{children}</p>
      <footer>
        <small>
          &mdash;{author} <br />
          <i>{title}</i> <br />
          <i>{location}</i>
        </small>
      </footer>
    </StyledQuote>
  );
};

export default Testimonial;
