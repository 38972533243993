import React from "react";
import styled from "styled-components";

import { useAnimationsContext } from "../context/animations-context";
import device from "../common/breakpoints";
import ParticleImage from "../components/ParticleImage";
import profileImage from "../assets/images/profile-home.jpeg";
import useWindowSize from "../hooks/useWindowSize";

const ImageContainer = styled.div`
  --image_height: 34vh;

  @media ${device.laptopS} {
    --image_height: 44vh;
  }

  @media ${device.laptopL} {
    --image_height: 48vh;
  }

  position: absolute;
  bottom: calc((100vh - var(--image_height)) / 2);
  right: 5vmin;
  height: var(--image_height);
  width: calc(var(--image_height) * 0.855);
  opacity: 1;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
`;

const FluidImage = styled.img`
  width: 100%;
  height: 100%;
`;

function HomeImage() {
  const [width, height] = useWindowSize();
  const { isAnimationsEnabled } = useAnimationsContext();
  const altText = "David Quirós, front-end engineer";
  const imageKey = `${width}x${height}`;

  return (
    <ImageContainer>
      {isAnimationsEnabled ? (
        <ParticleImage key={imageKey} src={profileImage} alt={altText} />
      ) : (
        <FluidImage src={profileImage} alt={altText} />
      )}
    </ImageContainer>
  );
}

export default HomeImage;
