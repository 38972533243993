import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import device from "../common/breakpoints";
import SocialMediaIcons, {
  SocialMediaIconsContainer,
} from "./SocialMediaIcons";
import useMedia from "../hooks/useMedia";

const StyledFooter = styled.footer`
  position: relative;
  text-align: center;
  padding-bottom: 12vh;
  /* Raise footer over animation canvas */
  z-index: 10;

  @media ${device.laptopS} {
    font-size: 1.2rem;
    padding-bottom: 6vh;
    top: -1rem;
  }

  @media ${device.laptopL} {
    top: 0;
  }

  > nav > ul {
    margin-top: 0.8rem;

    @media ${device.laptopS} {
      margin-top: 2.5vh;
    }

    li > a {
      color: var(--color_link);
      border-width: 1px;
      border-style: solid;
      border-color: var(--color_link);
      border-radius: 25px;
      padding: 0.5rem 1.8rem;
      margin: 0 1.5rem 0;
      display: inline-block;
      text-decoration: none;
      transition: all 0.4s ease;

      @media ${device.laptopL} {
        padding: 0.6rem 2.5rem;
        margin: 0 1.5rem 0;
      }

      &:hover {
        color: var(--color_text_inverse);
        background-color: var(--color_link);
      }
    }
  }

  ul > li {
    display: inline-block;
  }

  ${SocialMediaIconsContainer} {
    margin-top: 1.5rem;

    @media ${device.laptopS} {
      margin-top: 3vh;
    }
  }

  .swipe-instruction {
    font-size: 1.2rem;

    > svg.fa-forward {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.8rem;
      vertical-align: -5px;
      color: var(--color_link);
      transform: rotate(90deg);
    }
  }
`;

function HomeFooter() {
  const isLaptop = useMedia([device.laptopS], [true], false);

  return (
    <StyledFooter>
      {isLaptop ? (
        <nav>
          <p>Follow the links to know more about me</p>
          <ul>
            <li>
              <Link className="character-link" to="character">
                Character
              </Link>
            </li>
            <li>
              <Link className="experience-link" to="experience">
                Experience
              </Link>
            </li>
            <li>
              <Link className="demos-link" to="demos">
                Demos
              </Link>
            </li>
          </ul>
        </nav>
      ) : (
        <p className="swipe-instruction">
          <FontAwesomeIcon icon={faForward} />
          Swipe down to navigate the site
        </p>
      )}
      <SocialMediaIcons />
    </StyledFooter>
  );
}

export default HomeFooter;
