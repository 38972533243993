import React from "react";
import styled from "styled-components";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";

import device from "../common/breakpoints";
import useMedia from "../hooks/useMedia";

const Styled = styled.div`
  &.carousel {
    @media ${device.tablet} {
      margin-top: 7rem;
    }
    @media ${device.tabletL} {
      margin-top: 1vh;
    }
  }

  &.static {
    display: flex;
    justify-content: space-around;
    margin-top: 4vh;
  }
`;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Testimonials = ({ children }) => {
  const isLargeLaptop = useMedia([device.laptopL], [true], false);

  if (isLargeLaptop) return <Styled className="static">{children}</Styled>;
  return (
    <Styled className="carousel">
      <AutoPlaySwipeableViews
        // autoplay={false}
        interval={6500}
        resistance={true}
        ignoreNativeScroll={true}
        slideClassName="slide"
        slideStyle={{
          padding: "0 1vmax 2vmax",
          backgroundColor: "transparent",
        }}
        style={{ padding: "0" }}
      >
        {children}
      </AutoPlaySwipeableViews>
    </Styled>
  );
};

export default Testimonials;
