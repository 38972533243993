import React from "react";
import styled from "styled-components";
import {
  faLinkedin,
  faTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import device from "../common/breakpoints";

export const SocialMediaIconsContainer = styled.ul`
  > li {
    display: inline-block;
  }

  a {
    display: inline-block;
    margin: 0 1.5rem;
    font-size: 2rem;
    color: var(--color_text);
    transition: color 0.3s ease;

    @media ${device.laptopL} {
      font-size: 2.5rem;
    }

    &:hover {
      color: var(--color_link);
    }
  }
`;

function SocialMediaIcons() {
  return (
    <SocialMediaIconsContainer>
      <li>
        <a
          target="_blank"
          rel="external noopener noreferrer"
          href="https://cr.linkedin.com/in/quirosdavid"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="external nofollow noopener noreferrer"
          href="https://twitter.com/kiaro"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="external noopener noreferrer"
          href="https://github.com/quiaro"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </li>
    </SocialMediaIconsContainer>
  );
}

export default SocialMediaIcons;
