import React, { useState } from "react";

const NavContext = React.createContext();

function NavContextProvider(props) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return <NavContext.Provider value={{ isNavOpen, setIsNavOpen }} {...props} />;
}

function useNavContext() {
  const context = React.useContext(NavContext);
  if (context === undefined) {
    throw new Error(`useNavContext must be used within a NavContextProvider`);
  }
  return context;
}

export { NavContextProvider, useNavContext };
