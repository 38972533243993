import React from "react";
import styled from "styled-components";

import device from "../common/breakpoints";

import { ReactComponent as MozillaLogo } from "../assets/icons/donations/mozilla-foundation.svg";
import { ReactComponent as WikimediaLogo } from "../assets/icons/donations/wikimedia-foundation.svg";
import CCSSLogo from "../assets/icons/donations/ccss.png";
// import FODLogo from "../assets/icons/donations/fod.png";
import NivoLogo from "../assets/icons/donations/nivo.png";
import ReachUILogo from "../assets/icons/donations/reach-ui.png";
import NPRLogo from "../assets/icons/donations/npr";

const Styled = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 2vh 5vw;

  @media ${device.tablet} {
    padding: 0 2vw 1rem;
  }

  li {
    display: inline-block;
    margin: 0.2rem 3vw;

    @media ${device.tablet} {
      margin: 0.4rem 1rem;
    }
  }

  .ccss > a > img {
    width: 11vmax;
    height: 11vmax;

    @media ${device.tablet} {
      width: 6vmax;
      height: 6vmax;
    }

    @media ${device.tablet} and (min-resolution: 1.1dppx) {
      width: 5vmax;
      height: 5vmax;
    }
  }

  /* .fod > a > img {
    width: 20vmax;

    @media ${device.tablet} {
      width: 10vmax;
    }
  } */

  .mozilla {
    margin: 0;

    > a > svg {
      width: 14vmax;

      @media ${device.tablet} {
        width: 8vmax;
      }
    }
  }

  .npr > a > svg {
    width: 12vmax;

    @media ${device.tablet} {
      width: 8vmax;
    }

    @media ${device.tablet} and (min-resolution: 1.1dppx) {
      width: 6vmax;
    }
  }

  .nivo > a > img {
    width: 8vmax;
    height: 8vmax;

    @media ${device.tablet} {
      width: 6vmax;
      height: 6vmax;
    }

    @media ${device.tablet} and (min-resolution: 1.1dppx) {
      width: 5vmax;
      height: 5vmax;
    }
  }

  .reach-ui > a > img {
    width: 8vmax;
    height: 8vmax;

    @media ${device.tablet} {
      width: 6vmax;
      height: 6vmax;
    }

    @media ${device.tablet} and (min-resolution: 1.1dppx) {
      width: 5vmax;
      height: 5vmax;
    }
  }

  .wikimedia > a > svg {
    width: 14vmax;

    @media ${device.tablet} {
      width: 6vmax;
    }
  }


      /* @media ${device.tablet} {
      width: 48%;
      padding: 0.8rem 1rem;
    } */

`;

const Donations = () => (
  <Styled>
    <li className="mozilla">
      <a
        href="https://www.mozilla.org/en-US/about/manifesto/"
        rel="external nofollow noopener"
        target="blank"
      >
        <MozillaLogo title="Mozilla Foundation" />
      </a>
    </li>
    <li className="ccss">
      <a
        href="https://www.ccss.sa.cr/"
        rel="external nofollow noopener"
        target="blank"
      >
        <img src={CCSSLogo} alt="Caja Costarricense del Seguro Social" />
      </a>
    </li>
    <li className="reach-ui">
      <a
        href="https://reacttraining.com/reach-ui/"
        rel="external nofollow noopener"
        target="blank"
      >
        <img
          src={ReachUILogo}
          alt="Reach UI - React Components for Accessibility"
        />
      </a>
    </li>
    <li className="nivo">
      <a
        href="https://nivo.rocks/"
        rel="external nofollow noopener"
        target="blank"
      >
        <img src={NivoLogo} alt="Nivo - Visualization Library" />
      </a>
    </li>
    <li className="wikimedia">
      <a
        href="https://wikimediafoundation.org/about/"
        rel="external nofollow noopener"
        target="blank"
      >
        <WikimediaLogo title="Wikimedia Foundation" />
      </a>
    </li>
    <li className="npr">
      <a
        href="https://www.npr.org/ethics/"
        rel="external nofollow noopener"
        target="blank"
      >
        <NPRLogo title="National Public Radio" />
      </a>
    </li>
    {/* <li className="fod">
      <a
        href="http://www.fod.ac.cr/index.php?option=com_content&view=article&id=5&Itemid=103"
        rel="external nofollow noopener"
        target="blank"
      >
        <img src={FODLogo} alt="Fundación Omar Dengo" />
      </a>
    </li> */}
  </Styled>
);

export default Donations;
