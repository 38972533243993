import styled from "styled-components";
import device from "../../common/breakpoints";

const ContentDemosSection = styled.div`
  margin-top: 3vmax;

  h1 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1.2rem;

    @media ${device.tablet} {
      font-size: 2.2rem;
      text-align: left;
      background: var(--color_text);
      color: white;
      display: inline-block;
      padding: 0.8rem 2rem 0.8rem 1.6rem;
      margin: 0 0 2rem 10vmax;
    }

    @media ${device.laptopL} {
      margin-left: 4vw;
    }
  }

  .slide {
    font-size: 1rem;
    background-color: transparent;
    opacity: 0.92;

    h3 {
      font-size: 1.1rem;
      padding: 0.6rem 1rem;
      color: var(--color_text_inverse);
      background-color: var(--color_text);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      @media ${device.laptopS} {
        font-size: 1.2rem;
        padding: 0.8rem 1.5rem;
      }
    }

    p {
      line-height: 1.4;
      padding: 0.5rem 1rem 0;

      @media ${device.tablet} {
        padding: 1rem 4vw 0;
      }

      @media ${device.laptopS} {
        padding: 0.5rem 2rem 0;
      }
    }
  }
`;

export default ContentDemosSection;
