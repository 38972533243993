import React from "react";
import { useSwipeable } from "react-swipeable";

import { SWIPE_VERTICAL } from "../common/config";
import { useNavContext } from "../context/navigation-context";
import device from "../common/breakpoints";
import useMedia from "../hooks/useMedia";

function SwipeHandler({ children }) {
  const { setIsNavOpen } = useNavContext();
  const isLaptop = useMedia([device.laptopS], [true], false);

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => setIsNavOpen(false),
    onSwipedDown: () => setIsNavOpen(true),
    ...SWIPE_VERTICAL,
  });

  const eventHandlers = isLaptop ? {} : swipeHandlers;

  return <div {...eventHandlers}>{children}</div>;
}

export default SwipeHandler;
