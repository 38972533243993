import * as React from "react"

function GCPLogo(props) {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <path
        d="M30 13H18l-7 11 7 11h12l7-11zm-6 16c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z"
        fill="#CFD8DC"
      />
      <path
        d="M34.8 7.2c-.5-.7-1.3-1.2-2.198-1.2H15.398C14.5 6 13.7 6.5 13.2 7.2l-5.8 9.5L12 24l6.3-10h20.598zM16 11c-.602 0-1-.398-1-1s.398-1 1-1 1 .398 1 1-.398 1-1 1zm16 0c-.602 0-1-.398-1-1s.398-1 1-1 1 .398 1 1-.398 1-1 1z"
        fill="#2196F3"
      />
      <path
        d="M18.3 34L7.399 16.8l-3.597 5.9c-.5.8-.5 1.902 0 2.698L13.2 40.801C13.7 41.6 14.5 42 15.4 42h9.1l5.102-8zM8 25c-.602 0-1-.398-1-1s.398-1 1-1 1 .398 1 1-.398 1-1 1zm8 14c-.602 0-1-.398-1-1s.398-1 1-1 1 .398 1 1-.398 1-1 1z"
        fill="#FFC107"
      />
      <path d="M7.398 16.8L12 24l2.398-3.7L8.5 15z" fill="#1976D2" />
      <path d="M24.602 42l5.097-8h-4.8l-2.297 8z" fill="#F9A825" />
      <path
        d="M44.2 22.7L38.897 14H29.7L36 24 24.602 42h8c.898 0 1.699-.5 2.199-1.2l9.5-15.402c.5-.898.5-1.898-.102-2.699zM32 39c-.602 0-1-.398-1-1s.398-1 1-1 1 .398 1 1-.398 1-1 1zm8-14c-.602 0-1-.398-1-1s.398-1 1-1 1 .398 1 1-.398 1-1 1z"
        fill="#DD2C00"
      />
      <path d="M38.898 14H29.7l2.5 3.898L40.102 16z" fill="#BF360C" />
    </svg>
  )
}

export default GCPLogo