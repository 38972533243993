import styled from "styled-components";
import device from "../../common/breakpoints";

const ContentSection = styled.div`
  margin-top: 3vmax;

  h1 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1.2rem;

    @media ${device.tablet} {
      font-size: 2.2rem;
      text-align: left;
      background: var(--color_text);
      color: white;
      display: inline-block;
      padding: 0.8rem 2rem 0.8rem 1.6rem;
      margin: 0 0 2rem 6vmax;
    }

    @media ${device.laptopL} {
      margin-left: 4vw;
    }
  }

  .container {
    position: relative;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    transform: translateX(-100vw);

    @media ${device.tablet} {
      position: relative;
      will-change: unset;
      transform: translateX(0);
    }

    &.view-all {
      transform: translateX(0);
    }

    > ul {
      @media ${device.tablet} {
        position: relative;
        width: 48%;
      }
    }
  }

  .item {
    padding: 0 0 0.6rem;

    @media ${device.tablet} {
      padding: 0 0 1rem;
    }

    @media ${device.tabletL} {
      padding: 0 0 2vh;
    }

    &.active {
      .content {
        visibility: visible;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);

        @media ${device.tablet} {
          opacity: 0.84;
          transform: translateX(107%);
          transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
        }
      }
    }

    .content {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 1rem;
      margin-bottom: 0.6rem;
      transform: translateX(100vw);
      background-color: white;
      opacity: 0.84;
      border-radius: 4px;
      overflow: hidden;

      @media ${device.tablet} {
        visibility: visible;
        top: 0;
        transform: translateX(0);
        transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1),
          transform 1ms ease 500ms; /* Move content section back after it's disappeared */
        opacity: 0;
        z-index: -1;
      }

      h3 {
        font-size: 1.1rem;
        padding: 0.6rem 1rem;
        color: var(--color_text_inverse);
        background-color: var(--color_text);

        @media ${device.tablet} {
          padding: 0.8rem 1rem;
        }
      }

      p {
        line-height: 1.4;
        padding: 0.5rem 1rem 0;

        @media ${device.tablet} {
          padding-top: 0.8rem;
          line-height: 1.5;

          &:last-of-type {
            padding-bottom: 1rem;
          }
        }
      }

      button {
        display: block;
        font-size: 1rem;
        border-top: 1px solid var(--color_link);
        padding: 0.6rem 1rem 0.8rem;
        margin-top: 0.6rem;
        width: 100%;
        color: var(--color_link);

        .icon {
          margin-right: 0.5rem;
          vertical-align: -3px;
        }

        @media ${device.tablet} {
          display: none;
        }
      }
    }
  }
`;

export default ContentSection;
