import React from "react";
import Loadable from "react-loadable";

import ContentContainer from "../components/styled/ContentContainer";
import ContentDemosSection from "../components/styled/ContentDemosSection";
import Loading from "../components/Loading";
import PageContainer from "../components/styled/PageContainer";
import SwipeHandler from "../components/SwipeHandler";

const AsyncDemosContent = Loadable({
  loader: () => import("../components/DemosContent"),
  loading: Loading,
  delay: 0.5, // 0.3 seconds
});

function Demos() {
  return (
    <SwipeHandler>
      <PageContainer demosPage>
        <ContentContainer>
          <ContentDemosSection>
            <h1>Demos</h1>
            {/* <p className="intro">
            This is a series of demos that I worked on to try out different
            technologies &mdash;including some that were new at the time&mdash;
            and learn new concepts. You can find the source code for these demos
            and other experiments in my{" "}
            <a href="https://github.com/quiaro">Github account</a>.
          </p> */}
            <AsyncDemosContent />
          </ContentDemosSection>
        </ContentContainer>
      </PageContainer>
    </SwipeHandler>
  );
}

export default Demos;
