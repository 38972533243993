import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import device from "../common/breakpoints";
import ContentContainer from "../components/styled/ContentContainer";
import PageContainer from "../components/styled/PageContainer";
import HeroText from "../components/HeroText";
import CTAWithHoverEffect from "../components/CTAWithHoverEffect";
import HomeFooter from "../components/HomeFooter";
import HomeImage from "../components/HomeImage";
import SwipeHandler from "../components/SwipeHandler";
import useMedia from "../hooks/useMedia";

const HomeLayout = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: 1fr;
  justify-content: space-between;
  height: 100vh;
  font-size: 1rem;

  > main {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > ul {
      margin-top: 0.6rem;

      @media ${device.tablet} {
        margin-top: 0.4rem;
      }

      @media ${device.laptopL} {
        margin-top: 0.6rem;
      }

      > li {
        line-height: 1.6;

        > svg.fa-check {
          width: 0.9rem;
        }
        > span {
          margin-left: 0.5rem;
        }
      }
    }
  }

  @media ${device.tablet} {
    font-size: 1.2rem;
  }
`;

function Home() {
  const isTablet = useMedia([device.tablet], [true], false);

  return (
    <SwipeHandler>
      <PageContainer homePage>
        <ContentContainer>
          {isTablet && <HomeImage />}
          <HomeLayout>
            <header>
              <HeroText />
            </header>
            <main>
              <p>If you would like to:</p>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Develop a minimal viable product (MVP)</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Develop a proof of concept (POC)</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Develop new features for your product</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  <span>Augment your engineering team</span>
                </li>
              </ul>
              <div>
                <CTAWithHoverEffect
                  action="mailto:david@quiros.online"
                  text="Let's talk"
                />
              </div>
            </main>
            <HomeFooter />
          </HomeLayout>
        </ContentContainer>
      </PageContainer>
    </SwipeHandler>
  );
}

export default Home;
