import React, { useState } from "react";
import clsx from "clsx";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import device from "../common/breakpoints";
import useMedia from "../hooks/useMedia";
import ButtonViewMore from "../components/ButtonViewMore";
import ContentContainer from "../components/styled/ContentContainer";
import ContentSection from "../components/styled/ContentSection";
import Donations from "../components/Donations";
import PageContainer from "../components/styled/PageContainer";
import Testimonial from "../components/Testimonial";
import Testimonials from "../components/Testimonials";
import SwipeHandler from "../components/SwipeHandler";

function Character() {
  const isTablet = useMedia([device.tablet], [true], false);
  const [index, setIndex] = useState(isTablet ? 1 : null);

  const onSelect = (index) =>
    setIndex((currentIndex) =>
      isTablet ? index : currentIndex === index ? null : index
    );

  return (
    <SwipeHandler>
      <PageContainer characterPage>
        <ContentContainer>
          <ContentSection>
            <h1>Character</h1>
            <div className={clsx("container", { "view-all": index === null })}>
              <ul>
                <li className={clsx("item", { active: index === 1 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(1)}
                    isActive={index === 1}
                  >
                    Passionate about work and its impact
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Passionate about the work and its impact</h3>
                    <p>
                      Since 2014, my work prospects have been greatly influenced
                      by company missions, challenging projects and talented
                      people. From a museum exhibit in Europe invoking the power
                      of dreams, to e-learning, to govtech, I've been inspired
                      and privileged to work with clients and people who view
                      technology as a positive catalyst for change and a means
                      of empowerment.
                    </p>
                    <button onClick={() => onSelect(1)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 5 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(5)}
                    isActive={index === 5}
                  >
                    Loves React
                  </ButtonViewMore>
                  <div className="content">
                    <h3>I love React</h3>
                    <p>
                      Over the years, I've worked with different web frameworks
                      and React has naturally become the one that best fits my
                      mental model for building web applications. However, I
                      don't mind stepping out of my comfort zone, and I would
                      welcome the opportunity to work with other web frameworks
                      (e.g. Vue, Svelte) or even programming languages (e.g.
                      Elm, Rust, Scala), if there are paths for growth as part
                      of a long term plan.
                    </p>
                    <button onClick={() => onSelect(5)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 3 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(3)}
                    isActive={index === 3}
                  >
                    Self-driven
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Self-driven</h3>
                    <p>
                      I started out working for a digital ad agency where I
                      became team lead and front-end lead developer. Looking to
                      grow into developing web apps, I moved on to a software
                      consulting company where I worked developing the client
                      app for the new version of Crafter Studio. Later, I joined
                      Edify where I led front-end development for several
                      projects including gooru.org, a personalised learning
                      solution used by school districts in California. Since
                      2016, I've worked mostly as an independent contractor
                      developing web apps for clients in the US and Canada. In
                      between, I've completed a couple of courses on fullstack
                      development and worked as product engineer for UrbanLeap,
                      a San Francisco based startup.
                    </p>
                    <button onClick={() => onSelect(3)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 4 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(4)}
                    isActive={index === 4}
                  >
                    Skilled communicator
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Skilled communicator</h3>
                    <p>
                      As a team member, communicating effectively has been
                      essential to participating in{" "}
                      <a href="https://stackoverflow.blog/2019/09/30/how-to-make-good-code-reviews-better">
                        constructive
                      </a>{" "}
                      <a href="https://google.github.io/eng-practices/review/reviewer/">
                        code reviews
                      </a>
                      , informing progress on tasks and providing feedback. As
                      an independent contractor, good communication has been
                      crucial to manage client expectations and gain their
                      trust. Finally, I believe good communication permeates
                      into the code itself and translates into meaningful
                      variable names, helpful documentation and comments that
                      explain the reasoning behind a solution or advise on edge
                      cases or missing functionality.
                    </p>
                    <p>
                      When it comes to writing, I've written a couple of{" "}
                      <a href="https://medium.com/@quiaro">
                        tech articles on authentication
                      </a>{" "}
                      that have been read by almost 3000 people.
                    </p>
                    <button onClick={() => onSelect(4)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 6 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(6)}
                    isActive={index === 6}
                  >
                    Bilingual &mdash;Spanish/English
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Bilingual &mdash;Spanish/English</h3>
                    <p>
                      My mother tongue is Spanish, though I was fortunate to
                      move to an English-speaking country when I was still in
                      elementary school. My wife is a native English-speaker,
                      but she's become fluent in Spanish so at home we speak
                      English during the week and Spanish on the weekends. My
                      clients are mostly in the United States and Canada so at
                      the office, I usually speak English as well. I also used
                      to speak French and I enjoy practicing it whenever
                      possible, though those opportunities are now hard to come
                      by.
                    </p>
                    <button onClick={() => onSelect(6)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
                <li className={clsx("item", { active: index === 2 })}>
                  <ButtonViewMore
                    onClick={() => onSelect(2)}
                    isActive={index === 2}
                  >
                    Affinities
                  </ButtonViewMore>
                  <div className="content">
                    <h3>Affinities</h3>
                    <p>
                      I'm fueled by the love and support of my wife and the
                      smiles and admiration from my children. I'm also deeply
                      inspired by the following projects and the work of the
                      following organizations &mdash;which I care about
                      supporting:
                    </p>
                    <Donations />
                    <button onClick={() => onSelect(2)}>
                      <FontAwesomeIcon className="icon" icon={faAngleLeft} />
                      Back to list
                    </button>
                  </div>
                </li>
              </ul>
              <Testimonials>
                <Testimonial
                  author="Erez Druk"
                  title="Co-Founder and CTO, UrbanLeap"
                  location="San Francisco, CA."
                >
                  Aside from a deep technical background and strong web
                  development experience, David also brought to the table good
                  product and design sense which was a big advantage for our
                  small team.
                </Testimonial>
                <Testimonial
                  author="Sebastien Mirolo"
                  title="CEO, DjaoDjin"
                  location="San Francisco, CA."
                >
                  I really appreciate his pro-active approach to bring issues
                  and questions forward.
                </Testimonial>
                <Testimonial
                  author="Mary P. Fox"
                  title="Founder, retail tech startup"
                  location="San Francisco, CA."
                >
                  He has an amazing eye for detail, takes pride in the quality
                  of his craft and balances innovative thoughts and ideas with
                  industry best practices, always keeping the customer
                  experience in mind.
                </Testimonial>
              </Testimonials>
            </div>
          </ContentSection>
        </ContentContainer>
      </PageContainer>
    </SwipeHandler>
  );
}

export default Character;
