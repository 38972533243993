import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import device from "../common/breakpoints";
import useMedia from "../hooks/useMedia";

const StyledLink = styled.a`
  --stroke_color: #111;
  --link_bubble_diameter: 12vmin;

  @media ${device.tablet} {
    --link_bubble_diameter: 5.5vmin;
  }

  display: inline-block;
  height: var(--link_bubble_diameter);
  position: relative;
  text-decoration: none;
  color: inherit;
  margin: 1rem 0;
  transition: all 0.2s ease;
  padding: 0 1.4rem;

  @media ${device.tablet} {
    padding: 0 1.8rem;
  }

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: calc(var(--link_bubble_diameter) / 2);
    background-color: var(--color_link);
    width: var(--link_bubble_diameter);
    height: var(--link_bubble_diameter);
    transition: all 0.3s ease;
    opacity: 0.5;
  }
  > span {
    position: relative;
    font-size: 1rem;
    line-height: var(--link_bubble_diameter);
    font-weight: bold;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    vertical-align: middle;

    @media ${device.tablet} {
      font-size: 1.2rem;
    }
  }
  > svg {
    position: relative;
    top: 2px;
    margin-left: 0.8rem;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke_color);
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }
  &.mobile,
  &:hover {
    ::before {
      width: 100%;
      background-color: var(--color_link);
      opacity: 1;
    }
    span {
      color: white;
    }
    svg {
      color: white;
      transform: translateX(0);
    }
  }
  &:active {
    transform: scale(0.96);
  }
`;

function CTAWithHoverEffect(props) {
  const isLaptop = useMedia([device.laptopS], [true], false);

  return (
    <StyledLink className={clsx({ mobile: !isLaptop })} href={props.action}>
      <span>{props.text}</span>
      <FontAwesomeIcon icon={faComments} />
    </StyledLink>
  );
}

export default CTAWithHoverEffect;
