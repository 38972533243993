import React from "react";
import styled from "styled-components";
import clsx from "clsx";

import { useNavContext } from "../../context/navigation-context";
import device from "../../common/breakpoints";

export const StyledHamburger = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  transition: 0.1s;
  margin: 10px 10px;
  cursor: pointer;
  display: inline-block;
  outline: 0;
  z-index: 100;

  span {
    width: 5px;
    height: 5px;
    background-color: var(--color_text);
    display: block;
    border-radius: 50%;
    position: absolute;

    @media ${device.tablet} {
      width: 6px;
      height: 6px;
    }
  }

  &:hover span {
    transform: scale(1.2);
    transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
  }

  & span:nth-child(1) {
    left: 0;
    top: 0;
  }

  & span:nth-child(2) {
    left: 12px;
    top: 0;
  }
  & span:nth-child(3) {
    right: 0;
    top: 0;
  }
  & span:nth-child(4) {
    left: 0;
    top: 12px;
  }
  & span:nth-child(5) {
    position: absolute;
    left: 12px;
    top: 12px;
  }
  & span:nth-child(6) {
    right: 0px;
    top: 12px;
  }
  & span:nth-child(7) {
    left: 0px;
    bottom: 0px;
  }
  & span:nth-child(8) {
    position: absolute;
    left: 12px;
    bottom: 0px;
  }
  & span:nth-child(9) {
    right: 0px;
    bottom: 0px;
  }

  &.open {
    transform: rotate(180deg);
    cursor: pointer;
    transition: 0.2s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  }
  &.open span {
    border-radius: 50%;
    transition-delay: 200ms;
    transition: 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  }
  &.open span:nth-child(2) {
    left: 6px;
    top: 6px;
  }
  &.open span:nth-child(4) {
    left: 6px;
    top: 18px;
  }
  &.open span:nth-child(6) {
    right: 6px;
    top: 6px;
  }
  &.open span:nth-child(8) {
    left: 18px;
    bottom: 6px;
  }
`;

function Hamburger() {
  const { isNavOpen, setIsNavOpen } = useNavContext();
  return (
    <StyledHamburger
      className={clsx({ open: isNavOpen })}
      type="button"
      onClick={() => setIsNavOpen(current => !current)}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </StyledHamburger>
  );
}

export default Hamburger;
